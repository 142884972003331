import type { IVesting } from "@/types/ethers/Vesting";
import { useSigner } from "@/wallet/wallet";
import { utils } from "ethers";
import { getVestingContract } from "./getters";

/**
 * Read Contract
 */

export async function getAllPools(): Promise<
  IVesting.VestingInfoStructOutput[]
> {
  return await getVestingContract().getAllPools();
}

export async function getReleasableAmount(
  pool: number,
  wallet: string,
): Promise<number> {
  const result = await getVestingContract().calculateReleasableAmount(
    pool,
    wallet,
  );
  return +utils.formatEther(result);
}

export async function getToken(): Promise<string> {
  return await getVestingContract().getToken();
}

// TODO: call this function from token contract
// export async function getTotalToken(address: string): Promise<number> {
//     return await getVestingContract().getTotalToken(address)
// };

export async function getVestedAmount(
  pool: number,
  wallet: string,
): Promise<number> {
  try {
    const result = await getVestingContract().calculateReleasableAmount(
      pool,
      wallet,
    );
    return +utils.formatEther(result);
  } catch (e) {
    throw new Error("Error on getting vested amount");
  }
}

export async function getVestingEntry(
  pool: number,
  wallet: string,
): Promise<IVesting.WhitelistInfoStructOutput> {
  try {
    return await getVestingContract().getVestingEntry(pool, wallet);
  } catch (e) {
    throw new Error("Error on getting vesting entry");
  }
}

export async function getPool(
  _pool: number,
): Promise<IVesting.WhitelistInfoStruct[]> {
  try {
    return await getVestingContract().getPool(_pool);
  } catch (e) {
    throw new Error("Error on getting pool");
  }
}

export async function getPoolInfo(
  pool: number,
): Promise<IVesting.VestingInfoStructOutput> {
  try {
    return await getVestingContract().getPoolInfo(pool);
  } catch (e) {
    throw new Error("Error on getting pool info");
  }
}

export async function hasEntry(pool: number, wallet: string): Promise<boolean> {
  try {
    return await getVestingContract().hasEntry(pool, wallet);
  } catch (e) {
    console.log(e);
    throw new Error("Error on checking has entry");
  }
}

/**
 * Write Contract
 */

export async function addPool(
  name: string,
  startDate: number,
  cliff: number,
  duration: number,
  initialUnlock: number,
  revocable: boolean,
): Promise<boolean> {
  try {
    const tx = await getVestingContract().addPool(
      name,
      startDate,
      cliff,
      duration,
      initialUnlock,
      revocable,
    );
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function addVestingEntry(
  pool: number,
  wallet: string,
  amount: number,
): Promise<boolean> {
  try {
    const tx = await getVestingContract().addVestingEntry(
      pool,
      wallet,
      utils.parseEther(amount.toString()),
    );
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function batchVestingEntries(
  pool: number,
  wallets: string[],
  amounts: number[],
  nonce: number,
): Promise<boolean> {
  try {
    // Ensure amounts array contains valid numbers
    const cleanedAmounts = amounts.map((amount) => {
      // Trim whitespace and convert to number
      const parsedAmount = Number(String(amount).trim());
      if (isNaN(parsedAmount)) {
        throw new Error(`Invalid amount detected: ${amount}`);
      }
      return parsedAmount;
    });

    // Convert cleaned numbers to Wei
    const amountsInWei = cleanedAmounts.map((amount) =>
      utils.parseEther(amount.toString()),
    );

    console.log({ amountsInWei });
    const tx = await getVestingContract().batchVestingEntries(
      pool,
      wallets,
      amountsInWei,
      { nonce },
    );
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function claimVestedAmount(
  pool: number,
  wallet: string,
): Promise<boolean> {
  try {
    const tx = await getVestingContract().claimVestedAmount(pool, wallet);
    await tx.wait(1);
    return true;
  } catch (e) {
    console.log(e);
    throw new Error("Error on claiming vested amount");
  }
}

export async function revokeEntry(
  pool: number,
  wallet: string,
): Promise<boolean> {
  console.log("yo");
  try {
    const tx = await getVestingContract().revokeEntry(pool, wallet);
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
}

// TODO: this function is not found in the ABI
// export async function setMaxTokenTransfer(
//   amount: number,
//   active: boolean
// ): Promise<boolean> {
//   try {
//     const tx = await getVestingContract().setMaxTokenTransfer(amount, active);
//     await tx.wait(1);
//     return true;
//   } catch (e: any) {
//     throw new Error(e);
//   }
// }

export async function setToken(address: string): Promise<boolean> {
  try {
    const tx = await getVestingContract().setToken(address);
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function editVestingStatus(
  pool: number,
  wallet: string,
  status: boolean,
): Promise<boolean> {
  try {
    const tx = await getVestingContract().editVestingStatus(
      pool,
      wallet,
      status,
    );
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function editPool(
  pool: number,
  name: string,
  startDate: number,
  cliff: number,
  duration: number,
  initialUnlock: number,
  revocable: boolean,
  revoked: boolean,
): Promise<boolean> {
  try {
    const tx = await getVestingContract().editPool(
      pool,
      name,
      startDate,
      cliff,
      duration,
      +initialUnlock,
      revocable,
      revoked,
    );
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e.message);
  }
}

export async function editVestingEntry(
  pool: number,
  wallet: string,
  amount: number,
): Promise<boolean> {
  try {
    const tx = await getVestingContract().editVestingEntry(
      pool,
      wallet,
      utils.parseEther(amount.toString()),
    );
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function transferToken(
  address: string,
  amount: number,
): Promise<boolean> {
  try {
    const tx = await getVestingContract().transferToken(address, amount);
    await tx.wait(1);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function getClaimableAmount(
  pool: number,
  wallet: string,
): Promise<number> {
  try {
    const result = await getVestingContract().calculateReleasableAmount(
      pool,
      wallet,
    );
    return +utils.formatEther(result);
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function isAdmin(): Promise<boolean> {
  const vestingContract = getVestingContract();
  const adminRole = await vestingContract.DEFAULT_ADMIN_ROLE();
  const signer = useSigner();
  return await vestingContract.hasRole(adminRole, await signer.getAddress());
}
